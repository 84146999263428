import React, { useContext } from "react";
import { IconSpin } from "../components/Icons";
import { AuthContext } from "./AuthProvider";

interface RequireAuthProps {
	/**
	 * A callback to authorize the user *in addition* to authentication; ie if your route requires no
	 * additional authorization, simply provide a callback that returns `true`. To mount a route that
	 * requires no authentication at all, assign to `null`.
	 * @default () => true
	 */
	authorize?: ((userDoc: any) => boolean) | null;

	/**
	 * Boolean for behavior when authorization callback returns false. If true,
	 * display a Forbidden component. If false, do not render content at all.
	 * @default false
	 */
	shouldForbid?: boolean;
}

/**
 * Ensures user is authorized before rendering children. Additional conditions can be
 * enforced via the `authorize` callback prop, which accepts the userDoc as a parameter.
 */
const RequireAuth: React.FC<RequireAuthProps> = ({ authorize = () => true, shouldForbid = false, children }) => {
	const ac = useContext(AuthContext);

	// Just provides easy toggling of auth during dev
	const shouldAuthorize = true;

	let renderComponent: React.ReactNode;

	if (shouldAuthorize && authorize !== null) {
		if (!ac.sessionChecked) {
			// Auth is required and user auth hasn't loaded yet - display Spinner
			renderComponent = <IconSpin name="spinner-third"></IconSpin>;
		} else if (ac.sessionChecked && ac.error) {
			renderComponent = <>Forbidden</>;
		} else if (!authorize(ac)) {
			// User is loaded, but doesn't pass custom auth check
			if (shouldForbid) {
				renderComponent = <>Forbidden</>;
			} else {
				renderComponent = <></>;
			}
		} else if (ac.currentUser && authorize(ac)) {
			renderComponent = children;
		} else {
			ac.redirectToLogin();

			renderComponent = <></>;
		}
	} else {
		// Just render - no authorization required
		renderComponent = children;
	}

	return <>{renderComponent}</>;
};

export default RequireAuth;
