import React, { useEffect, useContext } from "react";
import Config from "../config";

//material-ui
import { Button, Grid } from "@material-ui/core";

import { AuthContext } from "../auth/AuthProvider";

const Forbidden = (props: any) => {
	const pageTitle = "Not Authorized";

	const pageButtons: any = [{}];

	const authContext = useContext(AuthContext);

	const pageCallbacks = async () => {
		props.setTheme("main");
		props.setPageTitle(pageTitle);
		props.setPageButtons(pageButtons);
	};

	useEffect(() => {
		pageCallbacks();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container justify="center" alignItems="center" className="home">
			<Grid item xs={12}>
				<h1>Not Authorized</h1>
				<p>Your account does not have access to this application. Please contact your administrator.</p>
			</Grid>
			<Grid item xs={12}>
				<Button variant="outlined" onClick={() => authContext.logOut()}>
					Try Logging In Again
				</Button>
			</Grid>
			<Grid item xs={12}>
				<p>App Name: mobile-app</p>
				<p>Build Version: {Config.buildVersion}</p>
				<p>Release Version: {Config.releaseVersion}</p>
			</Grid>
		</Grid>
	);
};
export default Forbidden;
