import React, { useEffect, useContext } from "react";

//ui
import Paper from "@material-ui/core/Paper";
import { IconSpin } from "../../components/Icons";
import "../../home.css";

//providers
import { AuthContext } from "../../auth/AuthProvider";

import Config from "../../config";
import SFGLogo from "../../assets/SF-Logo-foronwhite.svg";
import SFGLogoDark from "../../assets/SFLogoDark.svg";
import { Button } from "@material-ui/core";

const Home = (props: any) => {
	const authContext: any = useContext(AuthContext);

	const pageTitle = "";

	const pageButtons: any = [];

	const pageCallbacks = async () => {
		props.setTheme("main");
		props.setPageTitle(pageTitle);
		props.setPageButtons(pageButtons);
	};

	useEffect(() => {
		pageCallbacks();

		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					registration.update();
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Paper style={{ padding: "1em", textAlign: "center" }}>
				{!authContext.sessionChecked ? (
					<>
						<h1>
							<IconSpin name="spinner-third" />{" "}
						</h1>
						<h4>&nbsp;</h4>
					</>
				) : (
					<>
						{!authContext.company || authContext.error ? (
							<>
								<h3>
									{" "}
									Sorry, something went wrong.
									<br /> Please <a href="mailto:info@silverfern.group?subject=Web App Account Issue">Contact Us</a> for assistance.
								</h3>
								<p>
									<b>Error was:</b> {authContext.error}
								</p>
								<p>
									<Button
										color="secondary"
										variant="contained"
										onClick={() => {
											authContext.logout();
											//.then(() => window.location.href = "/");
										}}
									>
										Sign Out
									</Button>
								</p>
							</>
						) : (
							<>
								<h1>{authContext.company?.name}</h1>
								<h4>Mobile App</h4>
							</>
						)}
					</>
				)}
			</Paper>
			<p></p>
			<p></p>
			<p></p>
			<Paper style={{ padding: "1em", textAlign: "center" }}>
				<img alt="SFG Logo" src={props.themeType === "dark" ? SFGLogoDark : SFGLogo} style={{ width: "80%", maxWidth: "500px" }} />
			</Paper>
			<p>Build Version: {Config.buildVersion}</p>
			<p>Release Version: {Config.releaseVersion}</p>
		</>
	);
};

export default Home;
