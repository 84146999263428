import React, { useContext, useState, useLayoutEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//Apollo
import { ApolloClient, ApolloLink, split, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "@apollo/client/cache";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

//Theme - material-ui
import CustomThemeProvider from "./providers/CustomThemeProvider";
import { CssBaseline } from "@material-ui/core";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBackward, faForward, faStop } from "@fortawesome/free-solid-svg-icons";
import {
	faBrackets,
	faPlus,
	faMinus,
	faTimes,
	faDivide,
	faEquals,
	faBackspace,
	faHome,
	faCalendarCheck,
	faTally,
	faBoxes,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faArrowAltUp,
	faCog,
	faExpand,
	faBars,
	faCaretSquareDown,
	faCaretSquareUp,
	faCloudDownload,
	faClipboardListCheck,
	faSync,
	faCalculatorAlt,
	faCamera,
	faCalendarPlus,
	faCompass,
	faInfo,
	faArrowLeft,
	faWindowClose,
	faMapMarkerAlt,
	faSpinnerThird,
	faCheck,
	faTimesCircle,
	faExclamationTriangle,
	faHourglass,
	faRetweet,
	faRepeat,
	faCalendarAlt,
	faBan,
	faClock,
	faTrash,
	faEdit,
	faRedo,
	faQuestionCircle,
	faQuestion,
} from "@fortawesome/pro-regular-svg-icons";

//providers
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { AuthContext } from "./auth/AuthProvider";

import Topbar from "./layout/Topbar";

library.add(
	faBrackets,
	faPlus,
	faMinus,
	faTimes,
	faDivide,
	faEquals,
	faBackspace,
	faHome,
	faCalendarCheck,
	faTally,
	faBoxes,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faArrowAltUp,
	faCog,
	faExpand,
	faBars,
	faCaretSquareDown,
	faCaretSquareUp,
	faCloudDownload,
	faClipboardListCheck,
	faSync,
	faCalculatorAlt,
	faCamera,
	faCalendarPlus,
	faCompass,
	faInfo,
	faArrowLeft,
	faWindowClose,
	faMapMarkerAlt,
	faSpinnerThird,
	faCheck,
	faTimesCircle,
	faExclamationTriangle,
	faHourglass,
	faRetweet,
	faRepeat,
	faCalendarAlt,
	faBan,
	faClock,
	faTrash,
	faEdit,
	faRedo,
	faBackward,
	faForward,
	faStop,
	faQuestionCircle,
	faQuestion
);

const App = () => {
	const authContext = useContext(AuthContext);
	const [client, setClient] = useState<ApolloClient<any> | null>(null);
	const [clientSet, setClientSet] = useState(false);
	const [updateAvailable, setUpdateAvailable] = useState(false);
	const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

	var currentProfile: MobileAppsProfile | undefined = undefined;

	let profile = sessionStorage.getItem("profile");
	if (!profile) {
		profile = "default";
	} else {
		profile = profile.toLowerCase();
	}
	sessionStorage.setItem("profile", profile);

	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		setUpdateAvailable(true);
		setWaitingWorker(registration.waiting);
	};

	const onSWSuccess = (registration: ServiceWorkerRegistration) => {
		console.log("sw success");
		console.log(registration);
	};

	const applyUpdate = () => {
		waitingWorker?.postMessage({ type: "SKIP_WAITING" });
		setUpdateAvailable(false);
		window.location.reload();
	};

	useLayoutEffect(() => {
		serviceWorkerRegistration.register({ onUpdate: onSWUpdate, onSuccess: onSWSuccess });
	}, []);

	currentProfile = authContext.profiles?.mobileApps?.find((p) => p.name === profile);

	if (!clientSet && currentProfile && !authContext.error) {
		setClientSet(true);

		const httpLink = createHttpLink({
			uri: currentProfile.graphUrl,
			credentials: "include",
		});

		const authLink = setContext((_, { headers, ...context }) => {
			let profile = sessionStorage.getItem("profile");
			if (profile) {
				profile = profile.toLowerCase();
			}
			return {
				headers: {
					...headers,
					...{ profile: profile },
				},
				...context,
			};
		});

		// Create a WebSocket link:
		const wsLink = new GraphQLWsLink(
			createClient({
				url: currentProfile.graphWS,
				connectionParams: async () => {
					return {
						headers: {
							...{ profile: profile },
						},
					};
				},
			})
		);

		// using the ability to split links, you can send data to each link
		// depending on what kind of operation is being sent
		const link = split(
			// split based on operation type
			({ query }) => {
				const definition = getMainDefinition(query);
				return definition.kind === "OperationDefinition" && definition.operation === "subscription";
			},
			wsLink,
			ApolloLink.from([authLink, httpLink])
		);

		setClient(
			new ApolloClient({
				link: link,
				cache: new InMemoryCache(),
				credentials: "include",
			})
		);
	}

	if (!client) {
		return (
			<>
				<CssBaseline />
				<CustomThemeProvider>
					<SnackbarProvider
						dense
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
					>
						<ConfirmProvider>
							<CssBaseline />
							<Topbar updateAvailable={updateAvailable} applyUpdate={applyUpdate} setClientSet={setClientSet} />
						</ConfirmProvider>
					</SnackbarProvider>
				</CustomThemeProvider>
			</>
		);
	}

	return (
		<ApolloProvider client={client}>
			<CssBaseline />
			<CustomThemeProvider>
				<SnackbarProvider
					dense
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<ConfirmProvider>
						<CssBaseline />
						<Topbar updateAvailable={updateAvailable} applyUpdate={applyUpdate} setClientSet={setClientSet} />
					</ConfirmProvider>
				</SnackbarProvider>
			</CustomThemeProvider>
		</ApolloProvider>
	);
};

export default App;
