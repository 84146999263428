import { Theme } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { themeOverrides, themeOverridesDark } from "./themeOverrides";
import { amber} from '@material-ui/core/colors';

export function themeCreator(theme: string, type: string): Theme {
	if(type === "dark"){ 
		if (themeMapDark[theme]) {
			return { ...themeMapDark[theme] };
		} else {
			return { ...themeMapDark["main"] };
		}
	}
	if (themeMap[theme]) {
		return { ...themeMap[theme] };
	} else {
		return { ...themeMap["main"] };
	}
}


const mainTheme = createMuiTheme({

	palette: {
		primary: {
			main: "#49664b" /*"#69936C"*/
		},
		secondary: {
			main: "#3F4948"
		},
		background: {
			default: "#f5f5f5"
		}
	},
	overrides: themeOverrides
});

const mainThemeDark = createMuiTheme({

	palette: {
		type: "dark",
		primary: {
			main: "#69936C"
		},
		secondary: {
			main: "#ffb74d"
		}
	},
	overrides: themeOverridesDark
});

const availabilityTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#1893AE"
		},
		secondary: {
			main: amber[500]
		},
		background: {
			default: "#f5f5f5"
		}
	},
	overrides: themeOverrides
});

const availabilityThemeDark = createMuiTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#1893AE"
		},
		secondary: {
			main: amber[500]
		}
	},
	overrides: themeOverridesDark
});

const boomcTheme = createMuiTheme({

	palette: {
		primary: {
			main: "#6296BC"
		},
		secondary: {
			main: amber[800]
		}
	},
	overrides: themeOverrides
});

const boomcThemeDark = createMuiTheme({

	palette: {
		type: "dark",
		primary: {
			main: "#6296BC"
		},
		secondary: {
			main: amber[800]
		}
	},
	overrides: themeOverridesDark
});

const countTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#a34900"
		},
		secondary: {
			main: "#3F4948"
		},
		background: {
			default: "#f5f5f5"
		}
	},
	overrides: themeOverrides
});

const countThemeDark = createMuiTheme({
	palette: {
		type:"dark",
		primary: {
			main: "#a34900"
		},
		secondary: {
			main: "#b0bec5"
		}
	},
	overrides: themeOverridesDark
});


const inventoryTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#990000"
		},
		secondary: {
			main: "#550000"
		},
		background: {
			default: "#f5f5f5"
		}
	},
	overrides: themeOverrides
});


const inventoryThemeDark = createMuiTheme({
	palette: {
		type:"dark",
		primary: {
			main: "#990000"
		},
		secondary: {
			main: "#d7ccc8"
		}
	},
	overrides: themeOverridesDark
});

const verifyTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#69936C"
		},
		secondary: {
			main: "#3F4948"
		},
		background: {
			default: "#f5f5f5"
		}
	},
	overrides: themeOverrides
});

const verifyThemeDark = createMuiTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#69936C"
		},
		secondary: {
			main: "#3F4948"
		}
	},
	overrides: themeOverridesDark
});



const themeMap: any = {
	main: mainTheme,
	availability: availabilityTheme,
	inventory: inventoryTheme,
	count: countTheme,
	boomc: boomcTheme,
	verify: verifyTheme
};


const themeMapDark: any = {
	main: mainThemeDark,
	availability: availabilityThemeDark,
	inventory: inventoryThemeDark,
	count: countThemeDark,
	boomc: boomcThemeDark,
	verify: verifyThemeDark
};