import React, { createContext, useEffect, useState } from "react";
import sfAuth from "./SFAuth";
import useInterval from "./useInterval";

const buildEnv = process.env.REACT_APP_BUILD_ENV!;

const authUrl = process.env["REACT_APP_" + buildEnv + "_AUTH_URL"]!;
const clientId = process.env["REACT_APP_" + buildEnv + "_CLIENT_ID"]!;
const tenantId = process.env["REACT_APP_" + buildEnv + "_TENANT_ID"]!;
const redirectUri = process.env["REACT_APP_" + buildEnv + "_SESSION_MANAGER_URI"]!;
const appName = process.env["REACT_APP_" + buildEnv + "_APPLICATION_NAME"]!;

const defaultAuthContext = {
	sessionChecked: false,
	loggedIn: false,
	currentUser: null,
	userIsInSilverFern: false,
	company: null,
	profiles: {
		mobileApps: null,
		inventory: null,
	},
	redirectToLogin: () => {},
	logOut: () => {},
	error: null,
};

export const AuthContext = createContext<AuthContext>(defaultAuthContext);

interface AuthProviderProps {
	children: React.ReactNode;
}

const TOKEN_DURATION_SECONDS = parseInt(process.env["REACT_APP_" + buildEnv + "_TOKEN_DURATION_SECONDS"]!);

function AuthProvider({ children }: AuthProviderProps) {
	const [loggedIn, setLoggedIn] = useState(false);
	const [sessionChecked, setSessionChecked] = useState(false);
	const [currentUser, setCurrentUser] = useState<Maybe<SFUser>>(null);
	const [company, setCompany] = useState<Maybe<SFCompany>>(null);
	const [mobileAppProfiles, setMobileAppProfiles] = useState<Maybe<MobileAppsProfile[]>>(null);
	const [inventoryProfiles, setInventoryProfiles] = useState<Maybe<InventoryProfile[]>>(null);
	const [authError, setAuthError] = useState<Maybe<string>>(null);

	/** Run initial session check */
	useEffect(() => {
		(async () => {
			try {
				//if no internet connection then don't check session
				//set user info from local storage
				if (!navigator.onLine) {
					let offlineAuthContext: OfflineAuthContext | null = JSON.parse(localStorage.getItem("offlineAuthContext")!);
					if (offlineAuthContext) {
						setLoggedIn(offlineAuthContext.loggedIn);
						setSessionChecked(offlineAuthContext.sessionChecked);
						setCurrentUser(offlineAuthContext.currentUser);
						setCompany(offlineAuthContext.company);
						setMobileAppProfiles(offlineAuthContext.profiles.mobileApps);
						setInventoryProfiles(offlineAuthContext.profiles.inventory);
					} else {
						setLoggedIn(false);
					}
				} else {
					const hasSession = await sfAuth.checkSession();
					if (!hasSession) {
						// Utility route to remain on page even without a session; helpful for
						// checking cookies without getting redirected
						if (window.location.pathname.startsWith("/no-redirect") || window.location.pathname.startsWith("/forbidden")) {
							return;
						}
						redirectToLogin();
					} else {
						setLoggedIn(true);
						await loadUserInfo();
					}
				}
			} catch (e) {
				setLoggedIn(false);
			} finally {
				setSessionChecked(true);
			}
		})();

		window.addEventListener("online", () => sfAuth.checkSession());
		return () => {
			window.removeEventListener("online", () => sfAuth.checkSession());
		};
	}, []);

	/** Check session/renew access token periodically */
	useInterval(() => {
		(async () => {
			try {
				const hasSession = await sfAuth.checkSession();
				if (!hasSession) {
					redirectToLogin();
				}
			} catch (e) {
				redirectToLogin();
			}
		})();
	}, (TOKEN_DURATION_SECONDS * 1000) / 5);

	function redirectToLogin(onSuccessReturnToPath: boolean = true) {
		const params = new URLSearchParams({
			client_id: clientId,
			redirect_uri: `${redirectUri}/oauth-redirect`,
			response_type: "code",
			scope: "openid profile email offline_access",
			state: JSON.stringify({
				// appName parameter through state for oauth redirect
				// (so we can use apps.silverfern.app and still pivot on domain)
				appName,
				// By default, return to current path after authentication; else return to root
				returnTo: onSuccessReturnToPath ? window.location.pathname : "/",
			}),
		});
		const qs = params.toString();
		window.location.href = `${authUrl}/oauth2/authorize?${qs}`;
	}

	function redirectToLogOut() {
		const params = new URLSearchParams({
			client_id: clientId,
			tenantId: tenantId,
		});
		const qs = params.toString();
		// appName parameter through state for oauth redirect (so we can use apps.silverfern.app and still pivot on domain)
		const logoutUrl = `${authUrl}/oauth2/logout?${qs}`;
		window.location.href = logoutUrl;
	}

	let userIsInSilverFern = false;
	const abbreviation = company?.abbreviation;
	if (abbreviation) {
		userIsInSilverFern = ["sfg", "sfd"].indexOf(company!.abbreviation.toLowerCase()) > -1;
	}

	async function loadUserInfo() {
		try {
			const user = await sfAuth.getCurrentUser();
			const company = await sfAuth.getCompany();
			const mobileAppProfiles = await sfAuth.getMobileAppsProfiles();
			// const inventoryProfiles = await sfAuth.getInventoryProfiles();

			setCurrentUser(user);
			setCompany(company);
			setMobileAppProfiles(mobileAppProfiles);
			// setInventoryProfiles(inventoryProfiles);
		} catch (e) {
			console.log("Error loading user info: ", e);
			setAuthError(`${e}`);
		}
	}

	const contextValue: AuthContext = {
		loggedIn,
		sessionChecked,
		currentUser: currentUser,
		userIsInSilverFern,
		company: company,
		profiles: {
			mobileApps: mobileAppProfiles,
			inventory: inventoryProfiles,
		},
		redirectToLogin: redirectToLogin,
		logOut: redirectToLogOut,
		error: authError,
	};

	let offlineContextValue: OfflineAuthContext = {
		loggedIn,
		sessionChecked,
		currentUser: currentUser,
		userIsInSilverFern,
		company: company,
		profiles: {
			mobileApps: mobileAppProfiles,
			inventory: inventoryProfiles,
		},
	};

	//save a copy of the current AuthContext to local storage if logged in and session hass benn checked
	if (offlineContextValue.loggedIn && offlineContextValue.sessionChecked) {
		localStorage.setItem("offlineAuthContext", JSON.stringify(offlineContextValue));
	}

	console.log("Context value: ", contextValue);
	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
