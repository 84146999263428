const buildEnv = process.env.REACT_APP_BUILD_ENV!;
const sessionManagerUrl = process.env["REACT_APP_" + buildEnv + "_SESSION_MANAGER_URI"]!;
const sfApiUrl = process.env["REACT_APP_" + buildEnv + "_SF_API_URL"]!;
const appName = process.env["REACT_APP_" + buildEnv + "_APPLICATION_NAME"]!;

export class SFAuth {
	private static _instance: SFAuth;

	// Singleton
	private constructor() {}

	public static get Instance() {
		return this._instance || (this._instance = new this());
	}

	async checkSession(): Promise<boolean> {
		// Skip check if offline so app can be used in offline mode
		// should also add a call to CheckSession immediately when back online
		if (!navigator.onLine) {
			return true;
		}
		const response = await fetch(`${sessionManagerUrl}/check-session?appName=${appName}`, {
			method: "HEAD",
			credentials: "include",
		});

		if (response.status === 200) {
			return true;
		}

		if (response.status === 401) {
			return false;
		}

		const text = await response.text();
		console.log("Text: ", text);

		const err = new Error("Error checking session");
		throw err;
	}

	async getCurrentUser(): Promise<Maybe<SFUser>> {
		const response = await fetch(`${sfApiUrl}/me`, {
			credentials: "include",
		});
		if (response.status === 200) {
			const userInfo: SFUser = await response.json();
			return userInfo;
		}

		if (response.status === 401) {
			return null;
		}

		const err = new Error("Error checking session");
		throw err;
	}

	async getCompany(): Promise<Maybe<SFCompany>> {
		const response = await fetch(`${sfApiUrl}/company`, {
			credentials: "include",
		});
		if (response.status === 200) {
			const company: SFCompany = await response.json();
			return company;
		}

		if (response.status === 401) {
			return null;
		}

		const err = new Error("Error fetching company.");
		throw err;
	}

	async getProfilesForProduct(productName: string): Promise<Maybe<SFAppProfile[]>> {
		const url = `${sfApiUrl}/profiles?productName=${productName}`;
		const response = await fetch(url, {
			credentials: "include",
		});

		if (response.status === 200) {
			const profiles: SFAppProfile[] = await response.json();
			return profiles;
		}

		if (response.status === 401) {
			return null;
		}

		const err = new Error("Error checking session");
		throw err;
	}

	async getMobileAppsProfiles(): Promise<Maybe<MobileAppsProfile[]>> {
		const profiles = await this.getProfilesForProduct("mobile");
		if (!profiles) {
			return null;
		}

		return profiles as MobileAppsProfile[];
	}

	async getInventoryProfiles(): Promise<Maybe<InventoryProfile[]>> {
		const profiles = await this.getProfilesForProduct("inventory");
		if (!profiles) {
			return null;
		}

		return profiles as InventoryProfile[];
	}
}

const sfAuth = SFAuth.Instance;

export default sfAuth;
